import React, { useContext, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { useState } from "react";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { clientReport } from "../schemas";
import Swal from "sweetalert2";
import useSortableData from "../../hooks/useSortableData";
import { Tooltip } from "react-tooltip";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";

export default function SalesReportBootcamp() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [DAteSeted, setDAteSeted] = useState(false);

  const [radioOption, setRadioOption] = useState("Detail");
  const [show, setShow] = useState(false);
  //adeel fetched role to check the role of the user and add checks accordingly
  const { role } = state;
// adeel - end

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);

  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [myDespositNames, setMyDespositNamesa] = useState([]);
  const [myDespositData, setMyDespositData] = useState([]);
  const [myNames, setMyNamesa] = useState([]);
  // const [totalResults, setTotalResults] = useState("0");
  // const [totalPages, setTotalPages] = useState("1");
  const [option, setOption] = useState("");
  const [exportLink, setExportLink] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getPaymentMethodinitial = async (br_Id) => {
      try {
        const responce = await UserServices.paymentNames(br_Id);
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setPaymentMethods([{ name: "All Payment Method", id: "" }, ...results]);
      } catch (err) {
        console.log(err);

        setPaymentMethods([{ name: "No Payment Method Found", id: "" }]);
      }
    };
    getPaymentMethodinitial(branch_id);
  }, []);

  const setDates = async (param) => {
    setDAteSeted(true);
    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);
        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };

  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);

      try {
        if (radioOption === "Detail") {
          setMyData([]);
          setMyNamesa([]);
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/orders/export?branch_id=" +
              branch_id +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&gender=" +
              values.gender +
              "&payment_method_id=" +
              values.pay_met +
              "&category=" +
              values.package_type
          );

          const responce = await UserServices.getDetailSaleReportBootcamp(
            branch_id,
            values
          );

          let myDataarr;
          let myDespositDataarr;
          let myDespositNamesarr;

          let myNamesarr;

          if (responce.status === 200) {
            myDataarr = responce.data.onspot;
            myDespositDataarr = responce.data.deposits;
            myDespositNamesarr = responce.data.PaymentTypeLater;
            myNamesarr = responce.data.PaymentType;

            const res = myDataarr;
            const nam = myNamesarr;
            const dop = myDespositDataarr;
            const later = myDespositNamesarr;

            const results = [];
            const resultsDeposit = [];
            const namesListDeposit = [];
            const namesList = [];

            res.map((value) => {
              value.map((value) => {
                results.push({
                  gender: value.gender,
                  name: value.name,
                  pending: parseInt(value.pending),
                  received: parseInt(value.received),
                  TotalPackagePrice: value.TotalPackagePrice,
                  DiscountedPrice: value.DiscountedPrice,
                  NetPrice: parseInt(value.NetPrice),
                  receiving_date: value.receiving_date,
                  category: value.category,
                  paymentType: value.paymentType,
                  payment_method_id: value.payment_method_id,
                  order_id: value.order_id,
                  cheque_number: value.cheque_number,
                  client_id: value.client_id,
                  tax: value.tax,
                });
                return null;
              });
              return null;
            });

            dop.map((value) => {
              value.map((value) => {
                resultsDeposit.push({
                  gender: value.gender,
                  name: value.name,
                  pending: parseInt(value.pending),
                  received: parseInt(value.received),
                  TotalPackagePrice: value.TotalPackagePrice,
                  DiscountedPrice: value.DiscountedPrice,
                  NetPrice: parseInt(value.NetPrice),
                  receiving_date: value.receiving_date,
                  category: value.category,
                  paymentType: value.paymentType,
                  payment_method_id: value.payment_method_id,
                  order_id: value.order_id,
                  cheque_number: value.cheque_number,
                  client_id: value.client_id,
                  tax: value.tax,
                });
                return null;
              });
              return null;
            });

            nam.map((value) => {
              value.map((value) => {
                namesList.push({
                  name: value.name,
                  payment_method_id: value.payment_method_id,
                  receiving_date: value.receiving_date,
                  client_id: value.client_id,
                });
                return null;
              });
              return null;
            });
            later.map((value) => {
              value.map((value) => {
                namesListDeposit.push({
                  name: value.name,
                  payment_method_id: value.payment_method_id,
                  receiving_date: value.receiving_date,
                  client_id: value.client_id,
                });
                return null;
              });
              return null;
            });

            setMyData([...results]);

            setMyNamesa([...namesList]);
            setMyDespositNamesa([...namesListDeposit]);

            setMyDespositData([...resultsDeposit]);

            setShow(true);
            setOption("Detail");

            // setLimit(responce.data.data.per_page);
            // setTotalResults(responce.data.totalRecord);
            // setTotalPages(responce.data.totalPages);
            // setCurrentPage(responce.data.data.current_page);
            setLoading(false);
          }
        } else {
          setMyData([]);
          setMyNamesa([]);
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/orders/export-summery?branch_id=" +
              branch_id +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&gender=" +
              values.gender +
              "&payment_method_id=" +
              values.pay_met +
              "&category=" +
              values.package_type
          );
          const responce = await UserServices.getSummerySaleReportAllNew(
            branch_id,
            values
          );

          let myDataarr;
          let myDataarrLater;

          if (responce.status === 200) {
            myDataarr = responce.data.immediate;
            myDataarrLater = responce.data.later;

            const res = myDataarr;
            const resLater = myDataarrLater;
            const results = [];
            const later = [];

            res.map((value) => {
              return results.push({
                Cash: value.Cash ? parseInt(value.Cash) : 0,
                Easypaisa: value.Easypaisa ? parseInt(value.Easypaisa) : 0,
                Online: value.Online ? parseInt(value.Online) : 0,
                Cheque: value.Cheque ? parseInt(value.Cheque) : 0,
                Credit_Card: value.Credit_Card
                  ? parseInt(value.Credit_Card)
                  : 0,
                Others: value.Others ? parseInt(value.Others) : 0,

                date: value.date,
              });
            });

            resLater.map((value) => {
              return later.push({
                Cash: value.Cash ? parseInt(value.Cash) : 0,
                Easypaisa: value.Easypaisa ? parseInt(value.Easypaisa) : 0,
                Online: value.Online ? parseInt(value.Online) : 0,
                Cheque: value.Cheque ? parseInt(value.Cheque) : 0,
                Credit_Card: value.Credit_Card
                  ? parseInt(value.Credit_Card)
                  : 0,
                Others: value.Others ? parseInt(value.Others) : 0,

                date: value.date,
              });
            });

            setMyData([...results]);

            setMyDespositData([...later]);

            setMyNamesa([]);

            setShow(true);
            setOption("Summary");

            setLoading(false);
          }
        }
      } catch (err) {
        setOption("Detail");
        if (radioOption === "Detail") {
        } else {
          setOption("Summary");
        }
        console.log(err);
        setShow(true);
        setExportLink();
        if (err.response.status === 401) {
          // setTotalResults("0");
          // setTotalPages("1");
          setMyData([]);
          setMyNamesa([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          // setTotalResults("0");
          // setTotalPages("1");
          setMyData([]);
          setMyNamesa([]);
          setLoading(false);
        } else {
          // setTotalResults("0");
          // setTotalPages("1");
          setMyData([]);
          setMyNamesa([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
    gender: "",
    pay_met: "",
    package_type: "7",
  };

  const Table = ({ data, name, desposit, despositNames }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    // Group sales data by sales ID
    const salesById = data.reduce((accumulator, currentSale) => {
      if (!accumulator[currentSale.order_id]) {
        accumulator[currentSale.order_id] = [];
        accumulator[currentSale.order_id].push(currentSale.NetPrice);
      }
      return accumulator;
    }, {});

    // Calculate total net price for each sales ID and overall net price
    let overallNetPrice = 0;

    Object.keys(salesById).forEach((order_id) => {
      const netPrices = salesById[order_id];
      const totalNetPrice = netPrices.reduce(
        (sum, netPrice) => sum + netPrice,
        0
      );

      overallNetPrice += totalNetPrice;
    });
    let total_pending = 0;
    let total_received = 0;
    let total_price = 0;
    let total_discount = 0;
    let total_net = 0;
    let recordOrder = 0;
    const generatePdf = () => {
      const doc = new jsPDF("p", "mm", "letter");
      const tables = document.querySelectorAll("table");
      const caption = document.querySelectorAll("caption");

      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 },
      };
      let currentY = 10; // Initialize Y position for the text
      let pageWidth = doc.internal.pageSize.width; // Get the page width
      let pageHeight = doc.internal.pageSize.height; // Get the page height
      tables.forEach((table, index) => {
        // if (index !== 0) {
        //   doc.addPage();
        // }

        const captionText = table.getAttribute("data-caption");
        const labelText = table.getAttribute("data-label");

        // Calculate the height of the current table
        let tableHeight = doc.autoTable.previous.finalY - currentY;

        // Check if the table fits on the current page
        if (currentY + tableHeight + 20 > pageHeight) {
          // If it doesn't fit, start a new page
          doc.addPage();
          currentY = 10;
        }

        doc.text(
          "Date - " + captionText + " and Payment Type - " + labelText,
          10,
          currentY
        );
        currentY += 5;

        doc.autoTable({
          html: table,
          startY: currentY,
          ...tableOptions,
        });
        // Draw a line at the bottom of the table to separate it
        const tableBottomY = doc.autoTable.previous.finalY + 5;
        const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
        doc.setDrawColor(0); // Set line color to black
        doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
        currentY = tableBottomY + 10; // Update the Y position for the next table
      });
      doc.save("sales-by-bootcamp.pdf");
    };

    const generatePdfSummary = () => {
      const doc = new jsPDF("p", "mm", "letter");
      const tables = document.querySelectorAll("table");
      const caption = document.querySelectorAll("caption");

      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 },
      };
      let currentY = 10; // Initialize Y position for the text
      let pageWidth = doc.internal.pageSize.width; // Get the page width
      let pageHeight = doc.internal.pageSize.height; // Get the page height
      tables.forEach((table, index) => {
        // if (index !== 0) {
        //   doc.addPage();
        // }

        const captionText = table.getAttribute("data-caption");

        // Calculate the height of the current table
        let tableHeight = doc.autoTable.previous.finalY - currentY;

        // Check if the table fits on the current page
        if (currentY + tableHeight + 20 > pageHeight) {
          // If it doesn't fit, start a new page
          doc.addPage();
          currentY = 10;
        }

        doc.text("Table " + (index + 1) + " - " + captionText, 10, currentY);
        currentY += 5;

        doc.autoTable({
          html: table,
          startY: currentY,
          ...tableOptions,
        });
        // Draw a line at the bottom of the table to separate it
        const tableBottomY = doc.autoTable.previous.finalY + 5;
        const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
        doc.setDrawColor(0); // Set line color to black
        doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
        currentY = tableBottomY + 10; // Update the Y position for the next table
      });
      doc.save("sales-by-bootcamp.pdf");
    };

    return option === "Detail" ? (
      <>
        {name.length ? (
          <>
            <div className="col-md-12 mt-2">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="mb-0 text-uppercase">Filtered Result</h5>
                </div>
                <div className="col-12 col-md-4 ms-auto text-end">
                  <button
                    className="btn btn-primary mx-2 file-download"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                  <a
                    href={exportLink}
                    rel="noreferrer"
                    // target="_blank"
                    className="btn btn-primary file-download"
                  >
                    <i className="bi bi-download" /> Excel
                  </a>
                </div>
              </div>
              <hr />
            </div>
            {name.map((nel, index) => (
              <div key={index}>
                {nel.receiving_date !== name[index - 1]?.receiving_date ? (
                  <h4 className="text-capitalize date-heading">
                    {moment(nel.receiving_date).format("MMMM Do YYYY")}
                  </h4>
                ) : null}
                <div className="card" key={index}>
                  <div className="card-header py-3">
                    <h5 className="mb-0 font-20">{nel.name}</h5>
                  </div>
                  <span className="d-none">{(total_pending = 0)}</span>

                  <span className="d-none">{(total_received = 0)}</span>
                  <span className="d-none">{(total_price = 0)}</span>
                  <span className="d-none">{(total_discount = 0)}</span>
                  <span className="d-none">{(total_net = 0)}</span>
                  <span className="d-none">{(recordOrder = 0)}</span>

                  <div className="card-body">
                    <div className="table-responsive ">
                      <table
                        className="table  table-borderless table-style-1"
                        data-caption={moment(nel.receiving_date).format(
                          "MMMM Do YYYY"
                        )}
                        data-label={nel.name}
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="no_sort">
                              Sr#
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("name")}
                              className={getClassNamesFor("name")}
                            >
                              Client
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("order_id")}
                              className={getClassNamesFor("order_id")}
                            >
                              Order ID
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("receiving_date")}
                              className={getClassNamesFor("receiving_date")}
                            >
                              Sale Date
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("TotalPackagePrice")}
                              className={getClassNamesFor("TotalPackagePrice")}
                            >
                              Total Price
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("DiscountedPrice")}
                              className={getClassNamesFor("DiscountedPrice")}
                            >
                              Discount
                            </th>

                            <th
                              scope="col"
                              onClick={() => requestSort("tax")}
                              className={getClassNamesFor("tax")}
                            >
                              GST
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("NetPrice")}
                              className={getClassNamesFor("NetPrice")}
                            >
                              Net Price
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("received")}
                              className={getClassNamesFor("received")}
                            >
                              Total Received
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("pending")}
                              className={getClassNamesFor("pending")}
                            >
                              Pending Balance
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("paymentType")}
                              className={getClassNamesFor("paymentType")}
                            >
                              Payment Type
                            </th>
                            {/* 
                              <th
                                scope="col"
                                onClick={() => requestSort("gender")}
                                className={getClassNamesFor("gender")}
                              >
                                Gender
                              </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {items.length ? (
                            items.map((el, index) =>
                              nel.receiving_date === el.receiving_date ? (
                                nel.payment_method_id ===
                                el.payment_method_id ? (
                                  <tr
                                    key={index}
                                    className={`${
                                      el.pending !== 0 ? "row-highlight" : ""
                                    }`}
                                  >
                                    <td className="d-none">
                                      {
                                        (total_pending =
                                          total_pending + el.pending)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {
                                        (total_price =
                                          total_price + el.TotalPackagePrice)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {
                                        (total_discount =
                                          total_discount + el.DiscountedPrice)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {(total_net = total_net + el.NetPrice)}
                                    </td>
                                    <td className="d-none">
                                      {
                                        (total_received =
                                          total_received + el.received)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {(recordOrder = recordOrder + 1)}
                                    </td>

                                    <td>{recordOrder}</td>
                                    <td>
                                      <Link to={"/client-home/" + el.client_id}>
                                        {el.name}
                                      </Link>
                                    </td>
                                    <td>{el.order_id}</td>

                                    <td>
                                      {moment(el.receiving_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={el.TotalPackagePrice}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={el.DiscountedPrice}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={el.tax}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={el.NetPrice}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={el.received}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={el.pending}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>
                                    <td>
                                      {el.paymentType}
                                      {el.cheque_number === "0" ? null : (
                                        <>
                                          <i
                                            className="bi bi-info-square note-here"
                                            data-tooltip-id={
                                              el.order_id + el.received
                                            }
                                            data-tooltip-content={
                                              el.cheque_number
                                            }
                                          ></i>
                                          <Tooltip
                                            id={el.order_id + el.received}
                                            place="right"
                                            className="note-tooltip"
                                            clickable
                                          />
                                        </>
                                      )}
                                    </td>
                                    {/* <td>{el.gender}</td> */}
                                  </tr>
                                ) : null
                              ) : null
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan="7"
                                className="text-center text-capitalize"
                              >
                                No record found
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan="4"></td>
                            {/* <td colSpan="1" className="expenses-num">
                                Total Price :{" "}
                                <CurrencyFormat
                                  value={total_price}
                                  displayType={"text"}
                                  thousandSpacing={"3"}
                                  thousandSeparator={true}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td> */}
                            {/* <td colSpan="1" className="expenses-num">
                                Total Discount :{" "}
                                <CurrencyFormat
                                  value={total_discount}
                                  displayType={"text"}
                                  thousandSpacing={"3"}
                                  thousandSeparator={true}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td> */}

                            {/* <td colSpan="1" className="expenses-num">
                                Total Net Price :{" "}
                                <CurrencyFormat
                                  value={total_net}
                                  displayType={"text"}
                                  thousandSpacing={"3"}
                                  thousandSeparator={true}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td> */}

                            <td colSpan="1" className="expenses-num">
                              Total Received :{" "}
                              <CurrencyFormat
                                value={total_received}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td colSpan="1" className="expenses-num">
                              Total Pending :{" "}
                              <CurrencyFormat
                                value={total_pending}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <table
                        className="table  table-borderless table-style-1 d-none"
                        data-caption="All Dates Totals"
                        data-label="All Payments Totals"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="no_sort">
                            Total Net Price
                            </th>
                            <th scope="col" className="no_sort">
                            Total Received{" "}
                            </th>
                            <th scope="col" className="no_sort">
                            Pending Balance{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                              <CurrencyFormat
                    value={overallNetPrice ? overallNetPrice : 0}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                              </td>
                              <td>
                              <CurrencyFormat
                    value={myData.reduce((a, v) => (a = a + v.received), 0)}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                              </td>
                              <td>
                              <CurrencyFormat
                    value={myData.reduce((a, v) => (a = a + v.pending), 0)}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                              </td>
                            </tr>
                        </tbody>
            </table>
            <div className="grand-total-cart bg-white card">
              <div>
                <div className="cart-entry">
                  Total Net Price{" "}
                  <CurrencyFormat
                    value={overallNetPrice ? overallNetPrice : 0}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                  <div className="clearfix"></div>
                </div>
                <div className="cart-entry">
                  Total Received{" "}
                  <CurrencyFormat
                    value={myData.reduce((a, v) => (a = a + v.received), 0)}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                  <div className="clearfix"></div>
                </div>
                <div className="cart-entry">
                  Pending Balance{" "}
                  <CurrencyFormat
                    value={myData.reduce((a, v) => (a = a + v.pending), 0)}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card">
            <div className="card-header py-3 bg-white text-danger">
              <h5 className="mb-0 font-20">No record found</h5>
            </div>
          </div>
        )}

        {despositNames.length ? (
          <>
            <div className="col-md-12 mt-2">
              <h5 className="mb-0 text-uppercase">Deposits</h5>
              <hr />
            </div>
            {despositNames.map((nel, index) => (
              <div key={index}>
                {nel.receiving_date !==
                despositNames[index - 1]?.receiving_date ? (
                  <h4 className="text-capitalize date-heading">
                    {moment(nel.receiving_date).format("MMMM Do YYYY")}
                  </h4>
                ) : null}
                <div className="card" key={index}>
                  <div className="card-header py-3">
                    <h5 className="mb-0 font-20">{nel.name}</h5>
                  </div>
                  <span className="d-none">{(total_pending = 0)}</span>

                  <span className="d-none">{(total_received = 0)}</span>
                  <span className="d-none">{(total_price = 0)}</span>
                  <span className="d-none">{(total_discount = 0)}</span>
                  <span className="d-none">{(total_net = 0)}</span>
                  <span className="d-none">{(recordOrder = 0)}</span>

                  <div className="card-body">
                    <div className="table-responsive ">
                      <table
                        className="table  table-borderless table-style-1"
                        data-caption={moment(nel.receiving_date).format(
                          "MMMM Do YYYY"
                        )}
                        data-label={nel.name}
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="no_sort">
                              Sr#
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("name")}
                              className={getClassNamesFor("name")}
                            >
                              Client
                            </th>

                            <th
                              scope="col"
                              onClick={() => requestSort("order_id")}
                              className={getClassNamesFor("order_id")}
                            >
                              Order ID
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("receiving_date")}
                              className={getClassNamesFor("receiving_date")}
                            >
                              Sale Date
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("TotalPackagePrice")}
                              className={getClassNamesFor("TotalPackagePrice")}
                            >
                              Total Price
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("DiscountedPrice")}
                              className={getClassNamesFor("DiscountedPrice")}
                            >
                              Discount
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("tax")}
                              className={getClassNamesFor("tax")}
                            >
                              GST
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("NetPrice")}
                              className={getClassNamesFor("NetPrice")}
                            >
                              Net Price
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("received")}
                              className={getClassNamesFor("received")}
                            >
                              Total Received
                            </th>
                            <th
                              scope="col"
                              onClick={() => requestSort("pending")}
                              className={getClassNamesFor("pending")}
                            >
                              Pending Balance
                            </th>

                            <th
                              scope="col"
                              onClick={() => requestSort("paymentType")}
                              className={getClassNamesFor("paymentType")}
                            >
                              Payment Type
                            </th>
                            {/* 
                                    <th
                                      scope="col"
                                      onClick={() => requestSort("gender")}
                                      className={getClassNamesFor("gender")}
                                    >
                                      Gender
                                    </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {desposit.length ? (
                            desposit.map((el, index) =>
                              nel.receiving_date === el.receiving_date ? (
                                nel.payment_method_id ===
                                el.payment_method_id ? (
                                  <tr key={index}>
                                    <td className="d-none">
                                      {
                                        (total_pending =
                                          total_pending + el.pending)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {
                                        (total_price =
                                          total_price + el.TotalPackagePrice)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {
                                        (total_discount =
                                          total_discount + el.DiscountedPrice)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {(total_net = total_net + el.NetPrice)}
                                    </td>
                                    <td className="d-none">
                                      {
                                        (total_received =
                                          total_received + el.received)
                                      }
                                    </td>
                                    <td className="d-none">
                                      {(recordOrder = recordOrder + 1)}
                                    </td>

                                    <td>{recordOrder}</td>
                                    <td>
                                      <Link to={"/client-home/" + el.client_id}>
                                        {el.name}
                                      </Link>
                                    </td>
                                    <td>{el.order_id}</td>

                                    <td>
                                      {moment(el.receiving_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={el.TotalPackagePrice}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={el.DiscountedPrice}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={el.tax}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={el.NetPrice}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={el.received}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={el.pending}
                                        displayType={"text"}
                                        thousandSpacing={"3"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => (
                                          <span>{value}</span>
                                        )}
                                      />
                                    </td>
                                    <td>
                                      {el.paymentType}
                                      {el.cheque_number === "0" ? null : (
                                        <>
                                          <i
                                            className="bi bi-info-square note-here"
                                            data-tooltip-id={
                                              el.order_id + el.received
                                            }
                                            data-tooltip-content={
                                              el.cheque_number
                                            }
                                          ></i>
                                          <Tooltip
                                            id={el.order_id + el.received}
                                            place="right"
                                            className="note-tooltip"
                                            clickable
                                          />
                                        </>
                                      )}
                                    </td>
                                    {/* <td>{el.gender}</td> */}
                                  </tr>
                                ) : null
                              ) : null
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan="7"
                                className="text-center text-capitalize"
                              >
                                No record found
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan="4"></td>

                            <td colSpan="1" className="expenses-num">
                              Total Received :{" "}
                              <CurrencyFormat
                                value={total_received}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td colSpan="1" className="expenses-num">
                              Total Pending :{" "}
                              <CurrencyFormat
                                value={total_pending}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <table
                        className="table  table-borderless table-style-1 d-none"
                        data-caption="All Dates Received"
                        data-label="All Payments Received"
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="no_sort">
                            Total Received
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                              <CurrencyFormat
                    value={desposit.reduce((a, v) => (a = a + v.received), 0)}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                              </td>
                            </tr>
                        </tbody>
            </table>
            <div className="grand-total-cart bg-white card">
              <div>
                <div className="cart-entry">
                  Total Received{" "}
                  <CurrencyFormat
                    value={desposit.reduce((a, v) => (a = a + v.received), 0)}
                    displayType={"text"}
                    thousandSpacing={"3"}
                    thousandSeparator={true}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <span>{value}</span>}
                  />
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    ) : option === "Summary" ? (
      <>
        <a
          href={exportLink}
          rel="noreferrer"
          // target="_blank"
          className="btn btn-primary mx-2 file-download float-end mb-3"
        >
          <i className="bi bi-download" /> Excel
        </a>
        <button
          className="btn btn-primary mx-2 file-download float-end mb-3"
          onClick={() => {
            generatePdfSummary(document.getElementById("report-POS"));
          }}
        >
          <i className="bi bi-file-earmark-pdf"></i> PDF
        </button>
        <div className="clearfix"></div>

        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">Sales Report</h5>
          </div>

          <div className="card-body">
            <div className="table-responsive ">
              <table
                className="table  table-borderless table-style-1"
                data-caption="Sales Report Summary"
              >
                <thead>
                  <tr>
                    <th scope="col" className="no_sort">
                      Sr#
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("date")}
                      className={getClassNamesFor("date")}
                    >
                      Sale Date
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("Cash")}
                      className={getClassNamesFor("Cash")}
                    >
                      Cash
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("Cheque")}
                      className={getClassNamesFor("Cheque")}
                    >
                      Cheque
                    </th>

                    <th
                      scope="col"
                      onClick={() => requestSort("Credit_Card")}
                      className={getClassNamesFor("Credit_Card")}
                    >
                      Credit Card
                    </th>

                    <th
                      scope="col"
                      onClick={() => requestSort("Easypaisa")}
                      className={getClassNamesFor("Easypaisa")}
                    >
                      Easypaisa
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("Online")}
                      className={getClassNamesFor("Online")}
                    >
                      Online
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("Others")}
                      className={getClassNamesFor("Others")}
                    >
                      Others
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("Total")}
                      className={getClassNamesFor("Total")}
                    >
                      Total Received
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.length ? (
                    items.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{moment(el.date).format("DD-MM-YYYY")}</td>

                        <td>
                          <CurrencyFormat
                            value={el.Cash ? el.Cash : 0}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={el.Cheque ? el.Cheque : 0}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>

                        <td>
                          <CurrencyFormat
                            value={el.Credit_Card ? el.Credit_Card : 0}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={el.Easypaisa ? el.Easypaisa : 0}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={el.Online ? el.Online : 0}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={el.Others ? el.Others : 0}
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td>
                          <CurrencyFormat
                            value={
                              (el.Cash ? el.Cash : 0) +
                              (el.Others ? el.Others : 0) +
                              (el.Easypaisa ? el.Easypaisa : 0) +
                              (el.Credit_Card ? el.Credit_Card : 0) +
                              (el.Cheque ? el.Cheque : 0) +
                              (el.Online ? el.Online : 0)
                            }
                            displayType={"text"}
                            thousandSpacing={"3"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center text-capitalize">
                        No record found
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="2"></td>
                    <td colSpan="1" className="expenses-num">
                      Cash
                      <CurrencyFormat
                        value={
                          myData.reduce((a, v) => (a = a + v.Cash), 0)
                            ? myData.reduce((a, v) => (a = a + v.Cash), 0)
                            : 0
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>

                    <td colSpan="1" className="expenses-num">
                      Cheque
                      <CurrencyFormat
                        value={
                          myData.reduce((a, v) => (a = a + v.Cheque), 0)
                            ? myData.reduce((a, v) => (a = a + v.Cheque), 0)
                            : 0
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>

                    <td colSpan="1" className="expenses-num">
                      Credit Card
                      <CurrencyFormat
                        value={
                          myData.reduce((a, v) => (a = a + v.Credit_Card), 0)
                            ? myData.reduce(
                                (a, v) => (a = a + v.Credit_Card),
                                0
                              )
                            : 0
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>

                    <td colSpan="1" className="expenses-num">
                      Easypaisa
                      <CurrencyFormat
                        value={
                          myData.reduce((a, v) => (a = a + v.Easypaisa), 0)
                            ? myData.reduce((a, v) => (a = a + v.Easypaisa), 0)
                            : 0
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>

                    <td colSpan="1" className="expenses-num">
                      Online
                      <CurrencyFormat
                        value={
                          myData.reduce((a, v) => (a = a + v.Online), 0)
                            ? myData.reduce((a, v) => (a = a + v.Online), 0)
                            : 0
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td colSpan="1" className="expenses-num">
                      Others
                      <CurrencyFormat
                        value={
                          myData.reduce((a, v) => (a = a + v.Others), 0)
                            ? myData.reduce((a, v) => (a = a + v.Others), 0)
                            : 0
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>

                    <td colSpan="1" className="expenses-num">
                      Total Received
                      <CurrencyFormat
                        value={
                          (myData.reduce((a, v) => (a = a + v.Online), 0)
                            ? myData.reduce((a, v) => (a = a + v.Online), 0)
                            : 0) +
                          (myData.reduce((a, v) => (a = a + v.Others), 0)
                            ? myData.reduce((a, v) => (a = a + v.Others), 0)
                            : 0) +
                          (myData.reduce((a, v) => (a = a + v.Easypaisa), 0)
                            ? myData.reduce((a, v) => (a = a + v.Easypaisa), 0)
                            : 0) +
                          (myData.reduce((a, v) => (a = a + v.Credit_Card), 0)
                            ? myData.reduce(
                                (a, v) => (a = a + v.Credit_Card),
                                0
                              )
                            : 0) +
                          (myData.reduce((a, v) => (a = a + v.Cheque), 0)
                            ? myData.reduce((a, v) => (a = a + v.Cheque), 0)
                            : 0) +
                          (myData.reduce((a, v) => (a = a + v.Cash), 0)
                            ? myData.reduce((a, v) => (a = a + v.Cash), 0)
                            : 0)
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* DEPOSIT SUMMARY  */}
        {desposit.length ? (
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Deposit Sales Report </h5>
            </div>

            <div className="card-body">
              <div className="table-responsive ">
                <table
                  className="table  table-borderless table-style-1"
                  data-caption="Desposit Sales Report Summary"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("date")}
                        className={getClassNamesFor("date")}
                      >
                        Sale Date
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("Cash")}
                        className={getClassNamesFor("Cash")}
                      >
                        Cash
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("Cheque")}
                        className={getClassNamesFor("Cheque")}
                      >
                        Cheque
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("Credit_Card")}
                        className={getClassNamesFor("Credit_Card")}
                      >
                        Credit Card
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("Easypaisa")}
                        className={getClassNamesFor("Easypaisa")}
                      >
                        Easypaisa
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("Online")}
                        className={getClassNamesFor("Online")}
                      >
                        Online
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("Others")}
                        className={getClassNamesFor("Others")}
                      >
                        Others
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("Total")}
                        className={getClassNamesFor("Total")}
                      >
                        Total Received
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {desposit.length ? (
                      desposit.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>{moment(el.date).format("DD-MM-YYYY")}</td>

                          <td>
                            <CurrencyFormat
                              value={el.Cash ? el.Cash : 0}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.Cheque ? el.Cheque : 0}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>

                          <td>
                            <CurrencyFormat
                              value={el.Credit_Card ? el.Credit_Card : 0}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.Easypaisa ? el.Easypaisa : 0}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.Online ? el.Online : 0}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.Others ? el.Others : 0}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={
                                (el.Cash ? el.Cash : 0) +
                                (el.Others ? el.Others : 0) +
                                (el.Easypaisa ? el.Easypaisa : 0) +
                                (el.Credit_Card ? el.Credit_Card : 0) +
                                (el.Cheque ? el.Cheque : 0) +
                                (el.Online ? el.Online : 0)
                              }
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center text-capitalize">
                          No record found
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="2"></td>
                      <td colSpan="1" className="expenses-num">
                        Cash
                        <CurrencyFormat
                          value={
                            desposit.reduce((a, v) => (a = a + v.Cash), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Cash), 0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td colSpan="1" className="expenses-num">
                        Cheque
                        <CurrencyFormat
                          value={
                            desposit.reduce((a, v) => (a = a + v.Cheque), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Cheque), 0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td colSpan="1" className="expenses-num">
                        Credit Card
                        <CurrencyFormat
                          value={
                            desposit.reduce(
                              (a, v) => (a = a + v.Credit_Card),
                              0
                            )
                              ? desposit.reduce(
                                  (a, v) => (a = a + v.Credit_Card),
                                  0
                                )
                              : 0
                          }
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td colSpan="1" className="expenses-num">
                        Easypaisa
                        <CurrencyFormat
                          value={
                            desposit.reduce((a, v) => (a = a + v.Easypaisa), 0)
                              ? desposit.reduce(
                                  (a, v) => (a = a + v.Easypaisa),
                                  0
                                )
                              : 0
                          }
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td colSpan="1" className="expenses-num">
                        Online
                        <CurrencyFormat
                          value={
                            desposit.reduce((a, v) => (a = a + v.Online), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Online), 0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td colSpan="1" className="expenses-num">
                        Others
                        <CurrencyFormat
                          value={
                            desposit.reduce((a, v) => (a = a + v.Others), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Others), 0)
                              : 0
                          }
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td colSpan="1" className="expenses-num">
                        Total Received
                        <CurrencyFormat
                          value={
                            (desposit.reduce((a, v) => (a = a + v.Online), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Online), 0)
                              : 0) +
                            (desposit.reduce((a, v) => (a = a + v.Others), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Others), 0)
                              : 0) +
                            (desposit.reduce((a, v) => (a = a + v.Easypaisa), 0)
                              ? desposit.reduce(
                                  (a, v) => (a = a + v.Easypaisa),
                                  0
                                )
                              : 0) +
                            (desposit.reduce(
                              (a, v) => (a = a + v.Credit_Card),
                              0
                            )
                              ? desposit.reduce(
                                  (a, v) => (a = a + v.Credit_Card),
                                  0
                                )
                              : 0) +
                            (desposit.reduce((a, v) => (a = a + v.Cheque), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Cheque), 0)
                              : 0) +
                            (desposit.reduce((a, v) => (a = a + v.Cash), 0)
                              ? desposit.reduce((a, v) => (a = a + v.Cash), 0)
                              : 0)
                          }
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
      </>
    ) : null;
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        generateReport(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Sales Report of Bootcamp</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="link-hirarcy">
                  <span className="text-danger">Reports</span> » Sales Report
                </div>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                {/* //adeel added a check to only slelect start date of 3 months ago only */}
                {role === "4" ? (
                <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Start date</label>
                      <input
                        type="date"
                        className={`form-control ${DAteSeted ? "glow" : ""}`}
                        placeholder="Enter Start date"
                        name="sdate"
                        value={values.sdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        max={new Date().toISOString().split('T')[0]} // Today's date
                        min={new Date(new Date().setMonth(new Date().getMonth() - 3))
                          .toISOString()
                          .split('T')[0]} // 3 months ago
                      />
                      {errors.sdate && touched.sdate ? (
                        <p className="text-danger">{errors.sdate}</p>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                )}
                {/* // adeel added a check to only slelect end date of 3 months ago only for sales account having role 4 */}
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Filter By Gender</label>
                    <select
                      className="form-select"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">All</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Others</option>
                    </select>
                    {/* <span className="text-danger">error message</span> */}
                    {errors.gender && touched.gender ? (
                      <p className="text-danger">{errors.gender}</p>
                    ) : null}
                  </div>
                </div>
                {/* //adeel added check that if it is sales login that he cannot generte report more then 3 months before */}
                {role === "3" || role === "1" ? (
                  <>
                    <div className="col-12">
                      <h5>Quick Dates</h5>
                    </div>
                    <div className="row quick multi-button custom-multi-button">
                      <div className="col-md-4">
                        <label className="form-label d-block">Last</label>
                        <input
                          type="button"
                          check-val="lastYear"
                          onClick={() => {
                            setDates("lastYear");
                          }}
                          className="generate_report"
                          defaultValue="Year"
                        />
                        <input
                          type="button"
                          check-val="lastQuarter"
                          onClick={() => {
                            setDates("lastQuarter");
                          }}
                          className="generate_report"
                          defaultValue="Quarter"
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="lastMonth"
                          onClick={() => {
                            setDates("lastMonth");
                          }}
                          className="generate_report"
                        />
                        <input
                          type="button"
                          defaultValue="Yesterday"
                          check-val="lastDay"
                          className="generate_report"
                          onClick={() => {
                            setDates("lastDay");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">To-Date</label>
                        <input
                          type="button"
                          defaultValue="Year"
                          check-val="toDateYear"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateYear");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Quarter"
                          check-val="toDateQuarter"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateQuarter");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="toDateMonth"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateMonth");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Today"
                          check-val="toDateToday"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateToday");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">Previous</label>
                        <input
                          type="button"
                          defaultValue="365 Days"
                          check-val="previous365"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous365");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="90 Days"
                          check-val="previous90"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous90");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="30 Days"
                          check-val="previous30"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous30");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="9 Days"
                          check-val="previous9"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous9");
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
  {/* //added a check that if it is sales login that he cannot generte report more then 3 months before */}
                <div className="clearfix" />
                <div className="col-12">
                  <h5>Options</h5>
                </div>
                <div className="col-md-6 col-lg-3 ">
                  <div className="custom-raio">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                          }}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Detail"
                          defaultChecked={true}
                          onChange={() => {
                            setRadioOption("Detail");
                          }}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12"></div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label fw-bold">
                      Search by Payment Method
                    </label>
                    {paymentMethods.length ? (
                      <select
                        className="form-select"
                        name="pay_met"
                        value={values.pay_met}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {paymentMethods.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="pay_met"
                        value={values.pay_met}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Payment Method</option>
                      </select>
                    )}

                    {errors.pay_met && touched.pay_met ? (
                      <p className="text-danger">{errors.pay_met}</p>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label fw-bold">
                        Search by Revenue Category
                      </label>
                      <select
                        className="form-select"
                        name="package_type"
                        value={values.package_type}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="" defaultValue="">
                          All Revenue Category
                        </option>
                        <option value="6">Registration </option>
                        <option value="1">Gym </option>
                        <option value="2">Trainer </option>
                        <option value="3">Guest Pass </option>
                        <option value="4">Small Group PT </option>
                        <option value="5">Nutrition </option>
                        <option value="7">Bootcamp </option>
                        <option value="8">Freezing </option>
                        <option value="9">Genral</option>
                        <option value="10">Cafe</option>
                      </select>
                    </div>
                  </div> */}

                <div className="clearfix"></div>
                <div className="col-md-4 col-lg-5"></div>
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5"></div>
              </form>
            </div>
          </div>
          {show ? (
            <Table
              data={myData}
              name={myNames}
              desposit={myDespositData}
              despositNames={myDespositNames}
            />
          ) : null}
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
